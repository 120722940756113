import { createHttpEndpoint } from '../../utils'

export type CollectEmailBody = {
  email: string
  subscriptionSource: string
  optin: boolean
  languageCode: string
  countryCode: string
}

// TODO: type endpoint body once PR has been merged
export const postCRMLead = createHttpEndpoint<void>({
  path: '/crm/leads',
  method: 'POST',
  operationId: 'postCRMLead',
})
