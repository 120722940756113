import { useRoute } from '#imports'
import { ref } from 'vue'

import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import {
  type CollectEmailBody,
  postCRMLead,
} from '@backmarket/http-api/src/api-specs-crm-service/crm-service/leads'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import translations from './EmailCollector.translations'

function buildBody({ email }: { email: string }): CollectEmailBody {
  const { market } = useMarketplace()
  const route = useRoute()
  const routeName = (route.params?.pageName || route.name) as string

  return {
    email,
    subscriptionSource: routeName,
    optin: true,
    languageCode: market.defaultLocale.split('_')[0],
    countryCode: market.countryCode,
  }
}

function useEmailCollectorToast() {
  const { openSuccessToast, openErrorToast } = useTheToast()
  const i18n = useI18n()

  const openEmailCollectorSuccessToast = () =>
    openSuccessToast({
      title: i18n(translations.toastSuccessTitle),
      content: i18n(translations.toastSuccessMessage),
    })

  return { openSuccessToast: openEmailCollectorSuccessToast, openErrorToast }
}

export function useCollectEmail() {
  const isLoading = ref(false)

  const emailCollected = ref(false)

  function track(trackingData: Tracking) {
    const { trackClick } = useTracking()
    trackClick(trackingData || {})
  }
  async function collectEmail({
    email,
    trackingData,
  }: {
    email: string
    trackingData: Tracking
  }) {
    isLoading.value = true

    track(trackingData)

    const { openErrorToast, openSuccessToast } = useEmailCollectorToast()

    try {
      const body = buildBody({ email })
      await $httpFetch(postCRMLead, { body })
      openSuccessToast()
      emailCollected.value = true
    } catch (err) {
      openErrorToast()
    } finally {
      isLoading.value = false
    }
  }

  return {
    collectEmail,
    isLoading,
    submitButtonIsDisabled: emailCollected,
  }
}
