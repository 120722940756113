export default {
  inputLabel: {
    id: 'email_collector_input_label',
    defaultMessage: 'E-mail',
  },
  toastSuccessTitle: {
    id: 'email_collector_toast_success_title',
    defaultMessage: 'Perfect!',
  },
  toastSuccessMessage: {
    id: 'email_collector_toast_success_message',
    defaultMessage: 'Thanks for subscribing to our newsletter',
  },
  formMandatoryError: {
    id: 'email_collector_mandatory_field_error',
    defaultMessage: 'This field is mandatory',
  },
  legalLinkText: {
    id: 'email_collector_legal_link_text',
    defaultMessage: 'Read the data protection policy',
  },
  legalTextShort: {
    id: 'email_collector_legal_text_short',
    defaultMessage: 'See offer conditions',
  },
  legalTextExpandedLabel: {
    id: 'email_collector_legal_text_expanded_label',
    defaultMessage: 'You can unsubscribe at any time.',
  },
  legalTextExpanded: {
    id: 'email_collector_legal_text_expanded',
    defaultMessage:
      'Non-cumulative promotional code sent by email following newsletter registration, valid for a minimum order of 250 euros and for 1 month from receipt.',
  },
}
